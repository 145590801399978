import { useEffect , useState } from "react"
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Controller, useForm } from "react-hook-form";
import { Button } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { postStudentAnswersFunc } from "../../store/slices/quizes/PostStudentAnswersSlicer";
import ReactQuill from "react-quill";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearQuizeData, showLessonQuizeFunc } from "../../store/slices/lessons/ShowLessonQuizeSlicer";
import blueCloudImg from "../../images/blue-cartoon-cloud-with-smiling-face_885831-11607-removebg-preview.png";
import CustomTimer from "./CustomTimer";
import { getAttmptFunc } from "../../store/slices/attempt-actions/GetAttemptSlicer";
import { useTranslation } from "react-i18next";
import timerImg from "../../images/undraw_in_no_time_-6-igu.svg";
import { showSingleLessonFunc } from "../../store/slices/lessons/ShowSingleLessonSlicer";
export default function QuizPage({lesson}) {
    const {quizId} = useParams();
    const {t} = useTranslation();
    const {loading:getQuizeLoading , data:quizeData} = useSelector(
        state => state.showLessonQuizeSlicer
    )
    const  {loading : postAnswersLoading , data:submitQuizResponse} = useSelector(
        state => state.postStudentAnswersSlicer
    );
    const { data: normalLessonData } = useSelector(
        state => state.showSingleLessonSlicer
    );
    const { data: resultsData } = useSelector(
        state => state.getAttemptSlicer
    );
    const {data:targetLessonData} = useSelector(
        state => state.setLessonWhichWillCompletItSlicer
    );
    const {loading:getNormalLessonLoading , data:lessonData} = useSelector(
        state => state.showSingleLessonSlicer
    );
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const completingApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${quizId}/complete`;
    const quizeApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${quizId}/submit-quiz`;
    const startQuizApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${quizId}/start-quiz`;
    const quizResultsApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${quizId}/quizzes`;
    const [quizQuestions , setQuizeQuestions] = useState([]);
    const {register , watch , handleSubmit , control , setValue} = useForm();
    const [aboutQuiz , setAboutQuiz] = useState({});
    const [submissionDone , setSubmissionDone] = useState();
    useEffect(() => {
        if(normalLessonData) {
            setAboutQuiz(normalLessonData?.data?.quiz)
        }
    },[normalLessonData])
    const startQuiz = () => {
        dispatch(showSingleLessonFunc(`${process.env.REACT_APP_PUBLIC_API}/api/lessons/${quizId}`))
        dispatch(showLessonQuizeFunc(startQuizApi))
    }
    useEffect(() => startQuiz() , [])
    useEffect(() => {
        if(quizeData) {
            setQuizeQuestions(quizeData.questions);
        }
        console.log(quizeData)
    },[quizeData])
    const handleChange = (event) => {
        console.log(event)
    };
    useEffect(() => console.log(lessonData) , [lessonData])
    const handleGetAnswersGrades = () => {
        dispatch(getAttmptFunc(quizResultsApi))
        .then(result => {
            if(result?.payload?.lesson) {
                const quizObj = result?.payload?.quizzes[0];
                const score = quizObj?.score;
                const passedStatus = quizObj?.status_passed;
                const questionsArray = quizObj?.questions;
                const allPivotObjectsOfQ = quizObj?.questions?.flatMap(Q => Q.pivot);
                const allGradePfQ = allPivotObjectsOfQ?.length >= 1 && allPivotObjectsOfQ.reduce((acc , current) => acc + current);
                console.log(allGradePfQ)
            }
        })
    }
    const handleSubmitAnswers = (data) => {
        if(data) {
            const mainForm = new FormData();
            for (const [key, value] of Object.entries(data)) {
                mainForm.append(`answers[${key}]`, value);
            }
            dispatch(postStudentAnswersFunc({url : quizeApi , answers : mainForm}))
            .then(result => {
                window.scrollTo(0,0);
                if(result?.payload?.success) {
                    // handleSetDefView();
                    toast.success(result?.payload?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    handleGetAnswersGrades();
                    dispatch(clearQuizeData());
                    window.history.back();
                }
            })
        }
    }
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
          container: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': colors }, { 'background': colors }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['blockquote', 'code-block'],
            ['image'],
            ['clean']
          ],
        }
    };
    const [submitionResult , setSubmitionResult] = useState(null)
    useEffect(() => {
        if(submitQuizResponse) {
            // targetLessonData?.id !== submitQuizResponse?.data?.quiz?.lesson_id && 
            submitQuizResponse?.success && setSubmitionResult(true)
        }
    },[submitQuizResponse , targetLessonData])
    useEffect(() => {
        if(quizId) {
            if(pathname?.startsWith("/quiz-page/")) {
                const handleBeforeUnload = (event) => {
                    // Customize the message to be displayed in the confirmation dialog
                    const message = 'Are you sure you want to leave? Your Answers may not be saved.';
                    event.returnValue = message; // Standard way to set the message for the confirmation dialog
                    return message; // Some browsers might require this for the message to be shown
                };
        
                const handlePopState = (event) => {
                    // Custom logic for handling back button
                    const message = 'Are you sure you want to leave? Your Answers may not be saved.';
                    if (window.confirm(message)) {
                        window.history.back();
                    } else {
                        window.history.pushState(null, null, window.location.href);
                    }
                };
        
                window.addEventListener('beforeunload', handleBeforeUnload);
                // window.addEventListener('popstate', handlePopState);
                window.onpopstate = () => {
                    handlePopState()
                }
                // Cleanup function to remove the event listeners when the component unmounts
                return () => {
                    window.removeEventListener('beforeunload', handleBeforeUnload);
                    window.onpopstate = () => {
                        handlePopState()
                    }
                };
            }
        }
    }, [quizId]);
    return (
        <>
        <div className="container">
            <div className="row py-5">
                <div className="col-12">
                    {
                        submissionDone
                        ?
                        <div className="text-center py-1 min-h-[60vh] flex flex-col justify-center items-center">
                            <img src={timerImg} alt="timer-img" className="w-[100px] mb-2" />
                            <h2>{t("Quiz has ended")}!</h2>
                        </div>
                        :
                        (
                            // lesson && !getQuizeLoading && !quizeData  
                            // ?
                            // <div className={`text-center py-1 min-h-[60vh] flex flex-col justify-center items-center`}>
                            //     {t("Read the exam instructions first, then start.")}
                            //     <Button variant="contained" className="mt-2"
                            //     onClick={startQuiz}
                            //     >{t("Start Quiz")}</Button>
                            // </div>
                            // :
                            (
                            getQuizeLoading
                            ?
                            <div className={`text-center py-1 min-h-[60vh] flex justify-center items-center flex-col`}>
                                <Spinner animation="border" />
                                {t("loading quiz please wait a moment")}..
                            </div>
                            :
                            (
                                lesson && aboutQuiz?.status_passed === "review"
                                ?
                                <div className={`text-center py-1 min-h-[60vh] flex flex-col justify-center items-center`}>
                                    <img src={blueCloudImg} alt="cloud img" className="w-[200px]"/>
                                    {t("Quize Under Reviews")}
                                </div>
                                :
                                <form
                                onSubmit={handleSubmit((data) => {
                                    handleSubmitAnswers(data)
                                })}
                                >
                                    {                    
                                    quizeData && !submitionResult
                                    ?
                                    (
                                        quizQuestions?.length >= 1
                                        ?
                                        <div className="relative questions-content custom-scroll col-sm-12 overflow-y-auto flex flex-col">
                                            <div className="quiz-timer relative flex justify-end items-center">
                                                <CustomTimer 
                                                    fullTimeInMinutes={lessonData?.data?.lesson?.duration}                                     
                                                    exitQuize={() => {
                                                    const submitBTN = document.querySelector(".submit-answer-btn");
                                                        submitBTN.click();
                                                    }}
                                                />
                                            </div>
                                            {
                                                quizQuestions
                                                &&
                                                quizQuestions.length >= 1
                                                &&
                                                quizQuestions.map((q , index) => {
                                                    if(parseInt(q?.type) === 1) {
                                                        return (
                                                            <div className="relative border-b border-slate-600 flex flex-col p-3 mb-1">
                                                                <FormControl>
                                                                    <FormLabel className="flex" id={`question-${q?.pivot?.question_id}`}>
                                                                        <div className="question-content" dangerouslySetInnerHTML={{__html: q?.title}} />
                                                                    </FormLabel>
                                                                    <RadioGroup
                                                                        aria-labelledby={`question-${q?.pivot?.question_id}`}
                                                                        name={q?.pivot?.question_id}
                                                                        // value={fieldValue || value}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <FormControlLabel 
                                                                            value="0" 
                                                                            control={<Radio {...register(q?.pivot?.question_id?.toString())} />} 
                                                                            label="True" 
                                                                        />
                                                                        <FormControlLabel 
                                                                            value="1" 
                                                                            control={<Radio {...register(q?.pivot?.question_id?.toString())} />} 
                                                                            label="False" 
                                                                        />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                        )
                                                    }
                                                    if(parseInt(q?.type) === 2) {
                                                        return <div className="relative border-b border-slate-600 flex flex-col p-3 mb-1">
                                                            <FormControl>
                                                                <FormLabel className="flex" id={`question-${q?.pivot?.question_id}`}>
                                                                    <div className="question-content" dangerouslySetInnerHTML={{__html: q?.title}} />
                                                                    {/* <p>درجة ( {q.pivot?.grade} )</p> */}
                                                                </FormLabel>
                                                                <RadioGroup
                                                                    aria-labelledby={`question-${q?.pivot?.question_id}`}
                                                                    name={q?.pivot?.question_id?.toString()}
                                                                >
                                                                    {
                                                                        q.options !== null && q.options.length >= 1
                                                                        &&
                                                                        q.options.map((option , index) => {
                                                                            console.log(q)
                                                                            return (
                                                                                <FormControlLabel 
                                                                                    key={index}
                                                                                    value={index} 
                                                                                    control={<Radio {...register(q?.pivot?.question_id?.toString())} />} 
                                                                                    label={<div className="question-content" dangerouslySetInnerHTML={{__html: option}} />} 
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </RadioGroup>
                                                                
                                                            </FormControl>
                                                        </div>
                                                    }
                                                    if(q?.type === 3) {
                                                        return <div className="relative flex flex-col p-3 mb-1 overflow-y-auto essay-question-content custom-scroll">
                                                        {/* return <div className="relative flex flex-col p-3 mb-1 essay-question-content"> */}
                                                            <FormLabel className="flex" id={`question-${q?.pivot?.question_id}`}>
                                                                <div className="question-content" dangerouslySetInnerHTML={{__html: q?.title}} />
                                                                {/* <p>درجة ( {q.pivot?.grade} )</p> */}
                                                            </FormLabel>
                                                            <div className="row p-0 mx-0 h-full">
                                                                <label className="flex items-center mb-1">
                                                                    <AppRegistrationIcon/>
                                                                    Your Answer
                                                                </label>
                                                                <Controller
                                                                    name={`${q?.pivot?.question_id?.toString()}`}
                                                                    control={control}
                                                                    // defaultValue=""
                                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                        <ReactQuill
                                                                            // onChange={onChange}
                                                                            onChange={(content) => {
                                                                                setValue(q?.pivot?.question_id?.toString() , content)
                                                                                console.log(content)
                                                                            }}
                                                                            onBlur={onBlur}
                                                                            value={watch(q?.pivot?.question_id?.toString())}
                                                                            // ref={ref}
                                                                            modules={modules}
                                                                            className="p-0 relative m-0 border-none outline-none form-control bg-white"
                                                                            theme="snow"
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                })
                                            }
                                        </div>
                                        :
                                        (
                                            quizeData && quizQuestions?.length < 1
                                            &&
                                            <div className="relative min-h-[60vh] col-sm-12 flex justify-center items-center">
                                                <h2>{t("There Is No Questions For This Quiz")}</h2>
                                            </div>
                                        )
                                    )
                                    :
                                    <div className="relative min-h-[60vh] col-sm-12 flex justify-center items-center">
                                        <h2>{t("Faild To Load Quiz Please Retry")}</h2>
                                    </div>
                                    }
                                    {
                                        (quizeData && quizQuestions?.length >= 1)
                                        ?
                                        <Button  variant="contained" className={`mt-2 ${postAnswersLoading ? "pointer-events-none opacity-50" : ""} submit-answer-btn`} type="submit">
                                            {
                                                postAnswersLoading
                                                ?
                                                <>{t("loading")}...</>
                                                :
                                                <>
                                                    <SendIcon />
                                                    {t("Send")}
                                                </>
                                            }
                                        </Button>
                                        :
                                        ""
                                    }
                                </form>
                            )
                            )
                        )
                    }
                </div>
            </div>
        </div>
        </>
    )
}