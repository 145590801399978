import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useEffect, useState } from 'react';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import QuizIcon from '@mui/icons-material/Quiz';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }} className='custom-rtl-direction' >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {/* {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />} */}
        <LastPageIcon /> {/* for rtl direction */}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
        <KeyboardArrowRight /> {/* for rtl direction */}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {/* {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />} */}
        <KeyboardArrowLeft />  {/* for rtl direction */}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {/* {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />} */}
        <FirstPageIcon /> {/* for rtl direction */}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
  return { name, calories, fat };
}
function createData2(requirment) {
  return { name : requirment?.required_lesson?.name , type : requirment?.required_lesson?.type , id : requirment?.required_lesson?.required_lesson_id };
}

export default function RequirmentsTable({requirments}) {
  const {t} = useTranslation();
  const {data:targetLessonData} = useSelector(
    state => state.setLessonWhichWillCompletItSlicer
  )
  const rowsFunc = () => {
    if (targetLessonData?.requirements && Array.isArray(targetLessonData?.requirements)) {
      const newArr = targetLessonData?.requirements.map((req) => ({
        name: req?.required_lesson?.name,
        type: req?.required_lesson?.type,
        minPassingGrade: req?.min_passing_grade
      }));
      return newArr;
    }
    return [];
  };
  const rows = rowsFunc();
  useEffect(() => {
    targetLessonData?.requirements && console.log(rowsFunc())
  },[targetLessonData])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if(document.querySelector(".MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel")) {
      document.querySelector(".MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel").innerHTML = "عدد الصفوف";
    }
    if(document.querySelector(".MuiBox-root.css-19kzrtu")) {
      document.querySelector(".MuiBox-root.css-19kzrtu").style.padding = "24px 0";
    }
  },[])
  const switchLessonType = (type) => {
    switch (type) {
      case "video":
        return <PlayCircleFilledIcon />;
      case "pdf":
        return <PictureAsPdfIcon />;
      case "file":
        return <FolderZipIcon />;
      case "quiz":
        return <QuizIcon />;
      case "rich_text":
        return <AssignmentIcon />;
      default:
        return <PictureAsPdfIcon />;
    }
  };
  return (
    <TableContainer component={Paper} className='custom-rtl-direction requirments-table dark:bg-darkBg'>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table" className='dark:bg-darkBg'>
        <TableBody className='dark:text-lightText'>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" align="right" className='dark:text-lightText'>
                {row.name}
              </TableCell>
              <TableCell style={{ width: 160 }} align="right" className='dark:text-lightText'>
                {switchLessonType(row.type)} {row.type} 
              </TableCell>
              <TableCell style={{ width: 160 }} align="right" className='dark:text-lightText'>
                {
                  row.minPassingGrade
                  ?
                  <>
                    {t("Minimum Grade")}
                    {" "}
                    ( {row.minPassingGrade} )
                    {t("Grade")}
                  </>
                  :
                  "-"
                }
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter className='dark:text-lightText'>
          <TableRow >
            <TablePagination
              className='dark:text-lightText'
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'Rows per page:',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}