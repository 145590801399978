import "./teacher-card.css";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function TeacherCard({teacher , id ,  teacherImg , name , baio , coursesCount , inSwiper}) {
    const {t} = useTranslation();
    return (
        <>
            <div className={`relative max-h-[300px] p-2 rounded-md ${inSwiper ? "col-sm-12 w-full" : "col-sm-12 col-md-3 instructor-card"}`}>
                <div className="card-content relative flex flex-col justify-center items-center py-3">
                    <div className="img-div relative w-[100px] h-[100px] rounded-[50%] overflow-hidden border-2 border-primary">
                        <img src={teacherImg ? teacherImg : "sca"} alt="teacher" className="absolute w-full h-full opacity-100" />
                    </div>
                    <h3 className="my-2 dark:text-lightText">{name}</h3>
                    <p className="text-sm my-2">{baio?.length >= 20 ? `${baio.slice(0,20)}...` : baio}</p>
                    <div className='relative flex justify-center items-center'>
                        <LibraryBooksIcon className='text-[17px] dark:text-lightText'/>
                        <span className='relative h-[20px] w-[1px] bg-slate-600 mx-1 dark:text-lightText'></span>
                        <h2 className="relative inline-flex dark:text-lightText">
                            {t("Courses")}: {coursesCount}</h2>
                    </div>
                </div>
                <Link to={`/instructor-profile/${id}`} className="absolute top-0 left-0 w-full h-full z-20"></Link>
            </div>
        </>
    )
}