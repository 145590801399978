import "./loading.css";
function Loading() {
    return (
        <>
        <div className="fixed w-full h-[100vh] top-0 left-0 bg-white dark:bg-darkBg flex flex-col justify-center items-center">
            <div className="loader"></div>
        </div>
        </>
    )
}
export default Loading;