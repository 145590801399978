import { useEffect, useState } from "react";
import PageHeader from "../../component/ui/PageHeader";
import { getTeachersFunc } from "../../store/slices/teachers/GetTeachersSlicer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import failedImg from "../../images/blank-sheet-with-magnifying-glass-icon-3d-rendering-3d-render-cartoon-icon-style-concept_457716-1702-removebg-preview.png"
import TeacherCard from "../Home/component/teachers/teacher-card/TeacherCard";
export default function TeachersPage () {
    const {t} = useTranslation();
    const {loading , data} = useSelector(
        state => state.getTeachersSlicer
    )
    const dispatch = useDispatch();
    const [teachresList, setTeachresList] = useState([]);
    const teachersApi = `${process.env.REACT_APP_PUBLIC_API}/api/teachers`;
  
    const getRankedSt = () => {
      dispatch(getTeachersFunc(teachersApi))
        .then(result => console.log(result));
    };
  
    useEffect(() => {
      getRankedSt();
    }, []);
  
    useEffect(() => {
      data  && setTeachresList(data);
    }, [data]);
    useEffect(()=>{
      window.scrollTo(0,0)
    },[])
    return (
        <>
        <PageHeader title="Our Instructors"/> 
        <div className="content-page py-[100px] relative">
            <div className="section-title text-center">
                <h2 className="section-title__title dark:text-lightText">{t("Our Expert Instructors")}</h2>
            </div>
            {
              loading
                ?
                <>
                  <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                  </div>
                </>
                :
                (
                  teachresList.length >= 1
                    ?
                    (
                      <div className="container-fluid">
                        <div className="row justify-center  gap-4">
                          {
                          teachresList.map((teacher, index) => {
                            return (
                              <TeacherCard
                                key={index}
                                id = {teacher.id}
                                name={teacher.name}
                                coursesCount={teacher.courses_count}
                                teacherImg={teacher.image_url}
                                baio={teacher.baio}
                                inSwiper = {false}
                              />
                            );
                          })
                          }
                        </div>
                      </div>
                    )
                    :
                    <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                      <img
                        loading="lazy"
                        src={failedImg}
                        alt="failed img"
                        className="w-[150px] mb-3"
                      />
                      <p className="">
                        {t("No Teachers available yet")}!
                      </p>
                    </div>
                )
            }
        </div>
        </>
    )
}