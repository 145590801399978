import "./Nav/custom-nav.css"
import { Link, useLocation, useNavigate } from "react-router-dom"
import platformLogoImg from "../images/1.png"
import { useContext, useEffect, useState } from "react";
import studentImg from "../images/graduated.png"
import { logout } from "../services/logoutAction";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import MobileNavBar from "./Nav/MobileNaveBar";
import CustomSearchOverLay from "./ui/search-overlay/CustomSearchOverLay";
import { useDispatch, useSelector } from "react-redux";
import { readAllNotficationsFunc } from "../store/slices/notfications-slicers/ReadAllNotficationsSlicer";
import NotficationsList from "./NotficationsList";
import { getNotficationsFunc } from "../store/slices/notfications-slicers/GetNotficationsSlicer";
import ContextApi from "../store/slices/Context";
import { categoriestApis } from "../store/slices/categories/CategoriesSlicer";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import ToggelerLang from "./toggeler-lang/ToggelerLang";
import { useTranslation } from "react-i18next";
import { ChangeTheam } from "../store/slices/theme/ChangeThemeSlicer";

const menuItems = [
    {
      title : "first",
    },
    {
      title : "netsed",
      submenu : [
        {
          title : "nnnn"
        },
        {
          title: "asclkaksc",
          submenu : [
            {
              title : "csca"
            },
            {
              title : "csca"
            },
          ]
        },
        {
          title : "sccac"
        }
      ]
    },
    {
      title : "scacs",
      submenu : [
        {
          title: "csa",
        },
        {
          title: "csa",
        }
      ]
    }
  ]


function LargModal({lgShow , setLgShow}) {
    return (
      <>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow()}
          aria-labelledby="example-modal-sizes-title-lg"
          style={{
            backgroundColor: "none",
            border: "none",
          }}
          className="z-[999999999999]"
        >
          <Modal.Body>
            <div className="search-popup__content">
              <form action="#">
                <label htmlFor="search" className="sr-only">
                  search here
                </label>
                {/* /.sr-only */}
                <input type="text" id="search" placeholder="Search Here..." />
                <button
                  type="submit"
                  aria-label="search submit"
                  className="thm-btn2"
                >
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
}

const DropdownItem = ({ item }) => {
    return (
      <div>
        <div 
            style={{ cursor: 'pointer'}}
            className="relative flex justify-start items-center hover:text-slate-800 transition-all duration-300 hover:bg-slate-100 dark:hover:bg-black w-full p-2"
            >
           <Link to={`/courses?category=${item.id}`} className="text-[16px] text-nowrap text-slate-800 dark:text-lightText">{item.name}</Link>
        </div>
      </div>
    );
};

const Dropdown1 = ({ items }) => {
    return (
        <div className={`flex flex-wrap ${items?.length > 2 ? "w-[400px]" : ""} p-2`}>
        {items?.length >= 1 && items.map((item) => (
            <DropdownItem key={item.id} item={item} />
        ))}
        </div>
    );
};

// function dropSingleItem ({item}) {
//     return (
//         <li>
//             {item.name}
//             {
//                 item.all_children?.length >= 1
//                 &&
//                 <i className={`fa-solid fa-angle-left mx-1  transition-all duration-700 text-[10px]  `}></i>
//             }
//             {
//                 item.all_children?.length >= 1
//                 &&
//                 <ul className="absolute bottom-0 left-0 bg-white">
//                     {
//                         item.all_children.map((child) => (
//                             <dropSingleItem item={child} key={child}/>
//                         ))
//                     }
//                 </ul>
//             }
//         </li>
//     )
// }
// const Dropdown1 = ({ items }) => {
//     return (
//         <div className={`flex flex-wrap ${items?.length > 2 ? "w-[400px]" : ""} p-2`}>
//         {items?.length >= 1 && items.map((item) => (
//             <dropSingleItem key={item.id} item={item} />
//         ))}
//         </div>
//     );
// };



function NavBar4() {
    const {t} = useTranslation();
    const {data:languageData} = useSelector(
        state => state.getLanguageSettingsSlicer
      )
    const user = JSON.parse(window.localStorage.getItem("userData"));
    // const userImg = user && user.data.student.image_url;
    const expire_date = window.localStorage.getItem("expire_date");
    const location = useLocation();
    const navigate = useNavigate();
    const nameLocation = location.pathname;
    const [lgShow , setLgShow] = useState(false);
    const [showMobileNav , setShowMobileNav] = useState();
    const [showSearch , setShowSearch] = useState();
    const [showNotficationsList , setShowNotficationsList] = useState(false);
    const dispatch = useDispatch();
    const notficationsApi = `${process.env.REACT_APP_PUBLIC_API}/api/notifications`;
    const cartApi = `${process.env.REACT_APP_PUBLIC_API}/api/carts`;
    const {data} = useSelector(state => state.getNotficationSlicer);
    const {loading:readAllNotsLoading} = useSelector(state => state.readAllNotficationsSlicer);
    const [unReadNotficationsCount , setUnReadNotficationsCount] = useState(0);
    const [readNotfications , setReadNotfications] = useState([]);
    const [mounted , setMounted] = useState();
    let lastDate = new Date(parseInt(expire_date) * 1000)
    const foramtLastData = lastDate.getTime();
    let [days , setDays] = useState();
    let [hours , setHours] = useState();
    let [minutes , setMinutes] = useState();
    let [seconds , setSeconds] = useState();
    let [showCategoriesList , setShowCategoriesList] = useState(false);
    const contextApi = useContext(ContextApi)
    const platformLogo = contextApi?.site_logo;
    const emails = contextApi && contextApi.generalSetting && contextApi.generalSetting.emails
    const phones = contextApi && contextApi.generalSetting && contextApi.generalSetting.phones
    const {loading:getCategoriesLoading , data:categoriesData} = useSelector(
        state => state.categoriesSlicer
    )
    const {loading:getUserDataLoading , data:userData} = useSelector(
        state => state.user
    )
    const {
        loading: profileLoading,
        data: profileData,
        error: profileError,
    } = useSelector((s) => s.profileSlicer);
    const [cartList , setCartList] = useState([]);
    const [categoriesList , setCategoriesList] = useState([]);
    const [studentData, setStudentData] = useState({});
    useEffect(() => {
      if(profileData) {
        setStudentData(profileData.student);
      }
    }, [profileData]);
    const getNotfications = async () => {
        dispatch(getNotficationsFunc(notficationsApi))
    }
    useEffect(() => {
        if(user && expire_date){
            getNotfications()
        }
    },[])
    useEffect(() => {
        const getCategories = () => {
            dispatch(categoriestApis())
            .then(result => {
                setCategoriesList(result.payload?.data?.categories)
            })
        }
        getCategories()
    } , [])
    useEffect(() => {
        data && data.read_notifications && setReadNotfications(data.read_notifications)
        data && data.unread_notifications_count && setUnReadNotficationsCount(data.unread_notifications_count)
    },[data])
    useEffect(()=> {
        console.log(userData)
    },[userData])
    useEffect(() => {
        let scrollHandler = () => {
            if (window.scrollY >= 100) {
                document.querySelector(".customNavbar").classList.add("scrolling");
            } else {
                document.querySelector(".customNavbar").classList.remove("scrolling");
            }
        };
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    const showMobileNavFunc = () => {
        setShowMobileNav(true)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(foramtLastData) {
                const currentTime = Date.now();
                const remainingTime = foramtLastData - currentTime;
                if (remainingTime <= 1000) {
                    logout();
                    clearInterval(intervalId);
                }
                // const minutes = Math.floor(remainingTime / (60 * 1000));
                const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
                const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
                const d = Math.floor((remainingTime / (1000 * 60 * 60 * 24)));
                const h = Math.floor(remainingTime % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
                setDays(d);
                setHours(h);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }, 2000);
    
        return () => clearInterval(intervalId);
    }, []);
    const readAllNotfications = async () => {
        try {
            dispatch(readAllNotficationsFunc(notficationsApi))
            .then(result => {
                if(result.payload.success === true) {
                    setUnReadNotficationsCount(0);
                    getNotfications()
                }
            })
        }
        catch (error) {
            toast.error(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    useEffect(() => {
        setTimeout(() => setMounted(true),200)
    },[])
    const handleSwitchDarkMode = () => {
        const localTheam = localStorage.getItem("them");
        if (localTheam) {
          document.documentElement.className = localTheam;
        } else {
          localStorage.setItem("them", "light");
        }
    }
    return (
        <>
            <div className={`transition-all py-3 lg:py-0 duration-500 customNavbar relative top-0 left-0 w-full z-[99999] bg-white dark:bg-darkBg`}>
                {
                    emails && phones &&
                    <div className="contacts-nav transition-all duration-500 relative w-full bg-white mb-1 sm:hidden lg:flex border-b">
                        <div className="relative contacts-div flex items-center justify-end w-[90%] mx-auto py-2">
                            <div className={`relative flex items-center mr-2 ${mounted ? "translate-y-0 opacity-100" : "translate-y-2 opacity-0"} transition-all duration-300`}>
                                <Link className="m-0 text-primary" to={`tel:${phones[0]}`}>
                                    {phones[0]}
                                    <i className="fa-solid fa-phone-volume mr-1"></i>
                                </Link>
                            </div>
                            <div className={`relative flex items-center mr-2 ${mounted ? "translate-y-0 opacity-100" : "translate-y-2 opacity-0"} transition-all duration-700`}>
                                <Link className="m-0 text-primary" to={`mailto:${emails[0]}`}>
                                    {emails[0]}
                                    <i className="fa-solid fa-envelope mr-1"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                <div className="nav-container relative w-[90%] mx-auto flex justify-between items-center">
                    <i className="fa-solid fa-list-ul togglerListIcon transition-all duration-500 flex lg:hidden flex-col justify-center items-center cursor-pointer w-6 h-5 relative text-slate-800 dark:text-lightText text-xl" onClick={() => {
                        showMobileNavFunc()
                    }}></i>
                    <div className={`logo-and-links-in-large-screen sm1:hidden lg:flex relative items-center transition-all duration-300`}>
                        <Link to="/" className={`transition-all duration-500 ${mounted ? "translate-x-0 opacity-100" : "translate-x-[10px] opacity-0"}`}>
                            <img loading="lazy" className="platform-logo w-[70px]" alt="logo" src={platformLogo ? platformLogo : platformLogoImg} />
                        </Link>
                        <ul className="flex links-list flex-col lg:flex-row py-2">
                            <li className={`relative text-center lg:mx-1 navbar-link ${mounted ? "active" : ""}`} style={{ "--i": "1.6s" }}>
                                <Link className={`block py-1  nav-link text-black dark:text-lightText transition-all duration-500 hover:translate-y-[-3px]`} to="/" onClick={() => {}}>
                                    {t("Home")}
                                </Link>
                            </li>
                            <li className={`relative text-center lg:mx-1 navbar-link ${mounted ? "active" : ""} courses-li group`} style={{ "--i": "1s" }}
                                onMouseOver={() => setShowCategoriesList(true)}
                            >
                                <Link className={`block py-1  nav-link text-black dark:text-lightText transition-all duration-500 hover:translate-y-[-3px]`} to="#" onClick={() => {}}>
                                    {t("Categories")}
                                    <i className="fa-solid fa-angle-left mx-2 text-sm rotate-[-90deg] group-hover:rotate-[90deg] transition-all duration-500"></i>
                                </Link>
                                <div className={`categoriesList absolute transition-all duration-500 max-w-[400px] rounded-b-md bg-white dark:bg-darkBg ${showCategoriesList ? "activeCategoriesList" : ""} shadow-md `}
                                    onMouseLeave={() => {
                                        setShowCategoriesList(false);
                                    }}
                                >
                                    {
                                        getCategoriesLoading
                                            ?
                                            <p className="text-sm dark:text-lightText">{t("loading...")}</p>
                                            :
                                            categoriesList && categoriesList.length >= 1 &&
                                            <Dropdown1 items={categoriesList} />
                                    }
                                </div>
                            </li>
                            <li className={`relative text-center lg:mx-1 navbar-link ${mounted ? "active" : ""}`} style={{ "--i": "1.3s" }}>
                                <Link className={`block py-1  nav-link text-black dark:text-lightText transition-all duration-500 hover:translate-y-[-3px]`} to="/instructors" onClick={() => {}}>
                                    {t("Instructors")}
                                </Link>
                            </li>
                            <li className={`relative text-center lg:mx-1 navbar-link ${mounted ? "active" : ""}`} style={{ "--i": "1s" }}>
                                <Link className={`block py-1  nav-link text-black dark:text-lightText transition-all duration-500 hover:translate-y-[-3px]`} to="/contact" onClick={() => {}}>
                                    {t("Contact Us")}
                                </Link>
                            </li>
                            <li className={`relative text-center lg:mx-1 navbar-link ${mounted ? "active" : ""}`} style={{ "--i": "1.3s" }}>
                                <Link className={`block py-1  nav-link text-black dark:text-lightText transition-all duration-500 hover:translate-y-[-3px]`} to="/about" onClick={() => {}}>
                                    {t("About Us")}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="profile-icon-and-search flex items-center">
                        {
                            nameLocation !== "/login" &&
                            user &&
                            <i className="fa-solid fa-bell relative text-slate-800 dark:text-lightText ml-4 cursor-pointer transition-all duration-500 hover:translate-y-[-4px]"
                                onClick={() => {
                                    readAllNotfications()
                                    setShowNotficationsList(!showNotficationsList)
                                }}
                            >
                                {
                                    parseInt(unReadNotficationsCount) >= 1 &&
                                    <span className="absolute top-[-5px] right-[-5px] w-[10px] h-[10px] text-white bg-red-500 flex justify-center items-center rounded-[50%] p-2 text-[10px]">{unReadNotficationsCount}</span>
                                }
                            </i>
                        }
                        <i className="fa-solid fa-moon cursor-pointer text-slate-800 dark:text-lightText"
                        onClick={()=> {
                            console.log("them changed");
                            dispatch(ChangeTheam())
                        }}
                        ></i>
                        <ToggelerLang />
                        {
                            nameLocation !== "/login" &&
                            user &&
                            <DropdownButton id="dropdown-basic-button" title={
                                <div className="flex">
                                    <img loading="lazy" src={studentData?.image_url ? studentData?.image_url : studentImg} className="w-[40px] h-[40px] mx-auto rounded-[50%]" alt="user img" />
                                </div>
                            }
                                className="rounded-md outline-none border-none show-session-timer sm:my-3 lg:m-0 login-icon text-center">
                                {
                                    studentData?.image_url
                                    ?
                                    <img loading="lazy" src={studentData?.image_url ? studentData?.image_url : studentImg} className="w-[70px] h-[70px] mx-auto rounded-[50%]" alt="user img" />
                                    :
                                    ""
                                }
                                {
                                    user && expire_date
                                    ?
                                    <>
                                        <Dropdown.Item className="my-1 text-center">
                                            <div className="relative">
                                                <h2 className="text-sm font-bold">{studentData?.name}</h2>
                                                <h2 className="text-sm">{studentData?.email}</h2>
                                            </div>
                                            <span className="block text-[12px]">{t("End Session In")}</span>
                                            <ul className="flex justify-center items-center text-[12px]">
                                                <br/>
                                                <li className={`${minutes < 1 ? "text-red-500" : ""}`}>{seconds >= 10 ? seconds : `0${seconds}`} {languageData?.defaultLang?.slug === "ar" ? "Sec" : "S"}</li>
                                                :
                                                <li>{minutes >= 10 ? minutes : `0${minutes}`} {languageData?.defaultLang?.slug === "ar" ? "Min" : "M"}</li>
                                                :
                                                <li>{hours >= 10 ? hours : `0${hours}`} {languageData?.defaultLang?.slug === "ar" ? "Hr" : "H"}</li>
                                                :
                                                <li>{days >= 10 ? days : `0${days}`} {languageData?.defaultLang?.slug === "ar" ? "Day" : "D"}</li>
                                            </ul>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="my-1 text-center">
                                            <i className="fa-solid fa-user mx-1"></i>
                                            <Link to="/profile">{t("Profile")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="my-1 text-center"
                                            onClick={() => logout()}
                                        >
                                            <i className="fa-solid fa-arrow-right-to-bracket mx-1"></i>
                                            <Link to="/login">{t("Logout")}</Link>
                                        </Dropdown.Item>
                                    </>
                                    :
                                    <>
                                        <Dropdown.Item className="my-1 dark:bg-darkBg">
                                            <i className="fa-solid fa-arrow-right-to-bracket mx-1 dark:text-lightText"></i>
                                            <Link to="/login" className="dark:text-lightText">{t("Login")}</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="my-1 dark:bg-darkBg">
                                            <i className="fa-solid fa-user-plus mx-1 dark:text-lightText"></i>
                                            <Link to="/register" className="dark:text-lightText">{t("Sign Up")}</Link>
                                        </Dropdown.Item>
                                    </>
                                }
                            </DropdownButton>
                        }
                        {
                            !user &&
                            <Button
                                onClick={() => navigate("/login")}
                            >{t("Sign In")} / {t("Sign Up")}</Button>
                        }
                        
                    </div>
                </div>
            </div>
            {
                showMobileNav &&
                <MobileNavBar
                    showMobileNav={showMobileNav}
                    hideShowMobileNav={() => setShowMobileNav(false)}
                    categories={categoriesList}
                />
            }
            <LargModal
                lgShow={lgShow}
                setLgShow={() => setLgShow(false)}
            />
            <NotficationsList
                show={showNotficationsList}
                onHide={() => setShowNotficationsList(false)}
                notficationsLoading={readAllNotsLoading}
                notfications={readNotfications}
            />
            <CustomSearchOverLay showSearch={showSearch} hideSearch={() => setShowSearch(false)} />
        </>
    )
    
}
export default NavBar4