import { createSlice } from "@reduxjs/toolkit";

const ChangeThemeSlicer = createSlice({
  name: "theam",
  initialState: {
    them: "light",
  },
  reducers: {
    setTheam: (state) => {
      const localTheam = localStorage.getItem("them");
      if (localTheam) {
        state.them = localTheam;
      } else {
        localStorage.setItem("them", "light");
      }
      document
        .getElementsByTagName("body")[0]
        .setAttribute("them-data", state.them);
        document.documentElement.className = state.them
    },

    ChangeTheam: (state) => {
      state.them = state.them === "light" ? "dark" : "light";
      localStorage.setItem("them", state.them);
      document
        .getElementsByTagName("body")[0]
        .setAttribute("them-data", state.them);
        document.documentElement.className = state.them
    },
  },
});

export const { ChangeTheam,setTheam } = ChangeThemeSlicer.actions;
export default ChangeThemeSlicer.reducer;
