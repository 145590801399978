// import { useContext } from "react";import ContextApi from "../../../store/slices/Context";
// import { Link } from "react-router-dom";
// import img from "../../../images/modern-3d-illustration-education-concept_145666-1728-removebg-preview.png"
// import emailIcon from "../../../images/about/mail.png";
// import adressIcon from "../../../images/about/adress.png";
// import phoneIcon from "../../../images/about/phone-call.png";
// import { useTranslation } from "react-i18next";
// export default function AboutSection() {
//   const {t} = useTranslation();
//   const contextApi = useContext(ContextApi);
//   const {contact_email} = contextApi;
//   const {contact_phone} = contextApi;
//   const {contact_address} = contextApi;
//     return <>
//       <section className="about-two">
//         <div className="about-container w-[90%] mx-auto row">
//           <div className="image-side relative col-sm-12 col-md-6 flex justify-center items-center">
//             <div className="image-div relative w-full h-[400px]">
//               <img src={img} alt="img" className="absolute top-0 left-0 w-full h-full"/>
//             </div>
//           </div>
//           <div className="text-side relative col-sm-12 col-md-6">
//             <div className="about-text relative flex flex-col">
//               <h2 className=" transition-all duration-500 hover:tracking-[2px] text-[20px] md:text-[30px] lg:text-[40px] font-bold text-slate-700 mb-2"> {t("من نحن ؟")}</h2>
//               {/* <div  dangerouslySetInnerHTML={{__html : more_about}}/> */}
//               <p>
// {t("                في Dr Academy، نقدم لك تجربة تعليمية فريدة ومميزة، حيث نؤمن بأن التعلم هو مفتاح النجاح والتقدم. منصتنا توفر مجموعة متنوعة من الدورات التعليمية المصممة بعناية لتلبية احتياجاتك التعليمية والمهنية. سواء كنت تسعى لاكتساب مهارات جديدة أو تحسين مهاراتك الحالية، ستجد لدينا ما يناسبك                انضم إلى مجتمعنا التعليمي اليوم واستفد من                محاضرات تفاعلية بجودة عالية.                مواد تعليمية محدثة وشاملة.                دعم مستمر من خبراء في مختلف المجالات.                مرونة في التعلم من أي مكان وفي أي وقت.                ابدأ رحلتك التعليمية الآن مع Dr Academy واجعل من التعليم مغامرة شيقة وممتعة!")}
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="about-container w-[90%] mx-auto row gap-2 justify-between py-4">
//           <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
//             <img src={emailIcon} alt="img" className=" my-2 w-[50px]" />
//             <Link to={`mailto:${contact_email}`} className="mb-1">{contact_email}</Link>
//           </div>
//           <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
//           <img src={phoneIcon} alt="img" className=" my-2 w-[50px]" />
//             <Link to={`https://wa.me/${contact_phone}`} target="_blank" className="mb-1">{contact_phone}</Link>
//           </div>
//           <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
//           <img src={adressIcon} alt="img" className=" my-2 w-[50px]" />
//             <Link className="mb-1">{contact_address}</Link>
//           </div>
//         </div>
//       </section>
//     </>
// };

import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../store/slices/Context";
import { Link } from "react-router-dom";
import aboutImg from "../../../images/modern-3d-illustration-education-concept_145666-1728-removebg-preview.png";
import emailIcon from "../../../images/about/mail.png";
import adressIcon from "../../../images/about/adress.png";
import phoneIcon from "../../../images/about/phone-call.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function AboutSection() {
  const { t } = useTranslation();
  const contextApi = useContext(ContextApi);
  const { contact_email } = contextApi;
  const { contact_phone } = contextApi;
  const { contact_address } = contextApi;
  const {loading:getUiSettingsLoading , data} = useSelector(
    state => state.getUiSettingsSlicer
  )
  const [img , setImage] = useState(null)
  useEffect(() => {
    data && setImage(data?.site_banner_about);
  },[data])
  return (
    <>
      <section className="about-two dark:bg-darkBg">
        <div className="about-container w-[90%] mx-auto row">
          <div className="image-side relative col-sm-12 col-md-6 flex justify-center items-center">
            <div className="image-div relative w-full h-[400px]">
              <img src={img ? img : aboutImg} alt="img" className="absolute top-0 left-0 w-full h-full" />
            </div>
          </div>
          <div className="text-side relative col-sm-12 col-md-6">
            <div className="about-text relative flex flex-col">
              <h2 className="transition-all duration-500 hover:tracking-[2px] text-[20px] md:text-[30px] lg:text-[40px] font-bold text-slate-700 dark:text-lightText mb-2">
                {t("Who Are We?")}
              </h2>
              <p>
                {t("About Content")}
              </p>
            </div>
          </div>
        </div>
        <div className="about-container w-[90%] mx-auto row gap-2 justify-between py-4">
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 dark:bg-darkBg dark:shadow-md dark:shadow-black hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
            <img src={emailIcon} alt="img" className="my-2 w-[50px]" />
            <Link to={`mailto:${contact_email}`} className="mb-1 dark:text-lightText">
              {contact_email}
            </Link>
          </div>
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 dark:bg-darkBg dark:shadow-md dark:shadow-black hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
            <img src={phoneIcon} alt="img" className="my-2 w-[50px]" />
            <Link to={`https://wa.me/${contact_phone}`} target="_blank" className="mb-1 dark:text-lightText">
              {contact_phone}
            </Link>
          </div>
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 dark:bg-darkBg dark:shadow-md dark:shadow-black hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
            <img src={adressIcon} alt="img" className="my-2 w-[50px]" />
            <Link className="mb-1 dark:text-lightText">
              {contact_address}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
