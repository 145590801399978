function ContactBox({icon , title , content}) {
    return (
        <>
            <div className="relative col-sm-12 flex flex-col justify-center items-center px-2 py-3 mb-2 border bg-white dark:bg-darkBg dark:border-none dark:shadow-md dark:shadow-black ">
                {icon}
                <h3 className="my-2 dark:text-lightText">{title}</h3>
                <p className="mb-1 dark:text-lightText">{content}</p>
            </div>
        </>
    )
}
export default ContactBox;