import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getLanguageSettingsFunc } from "../../store/slices/language/GetLanguageSettingsSlicer";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
export default function ToggelerLang() {
  const {t} = useTranslation();
	const {loading:getLanguageLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
	const [languagesList , setLanguagesList] = useState([])
	useEffect(() => {
		languageData && languageData.languages && setLanguagesList(languageData.languages);
	},[languageData])
  const languageAPi = `${process.env.REACT_APP_PUBLIC_API}/api/language-settings`;
  const dispatch = useDispatch();
  const [langSlug , setlangSlug] = useState("")
  useEffect(() => {
    if(languageData && languageData.defaultLang) {
      const dfLangSlug = languageData.defaultLang.slug
      setlangSlug(dfLangSlug)
    }
  },[languageData])
  const handleGetLang = (lang) => {
    if(lang.slug) {
      if(lang.slug !== langSlug) {
        dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${lang.slug}`));
      }
    }
  }
  return (
    <Dropdown className="">
      <Dropdown.Toggle variant="success" id="dropdown-basic" className="p-0 m-0 bg-transparent outline-none border-none toggeler-lang-btn group after:hidden before:hidden">
        <div className="relative flex justify-center items-center">
          <p className="relative transition-all duration-300 translate-x-[-10px] opacity-0 group-hover:translate-x-0 group-hover:opacity-100">{langSlug}</p>
          <i className="fa-solid fa-language text-slate-800 dark:text-lightText"></i>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dark:bg-darkBg">
          {
            getLanguageLoading
            ?
            "جاري التحميل..."
            :
            (
                languagesList && languagesList.length >= 1
                ?
                languagesList.map((lang , index) => (
                <Dropdown.Item key={index} href="#" className="dark:text-lightText hover:bg-transparent" onClick={() => handleGetLang(lang)}>{lang?.name}</Dropdown.Item>
              ))
              :
              <Dropdown.Item href="#" style={{pointerEvents : "none"}}>{t("No Languages")}</Dropdown.Item>
            )
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}
